<template>
  <div class="loading">
    <LottieAnimation v-if="theme === 'dark'" class="loading__icon" name="loading-dark" />
    <LottieAnimation v-else class="loading__icon" name="loading-white" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "@/components/ui/LottieAnimation.vue";

export default {
  components: { LottieAnimation },
  computed: { ...mapGetters("app", ["theme"]) },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__icon {
    width: 4rem;
    height: 4rem;
    color: $gray-200;
  }
}
</style>

<style lang="scss">
.theme-dark {
  .loading {
    &__icon {
      color: $gray-900;
    }
  }
}
</style>
