function convertToRegex(string) {
  const escaped = string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  return new RegExp(`^${escaped.replace("*", ".*")}`);
}

export default (uri, list) => {
  if (!list?.length) return false;

  return list.some(allowed => {
    // If allowed uri contains *, treat it as regex (but only accepts the * pattern)
    if (allowed.includes("*")) return !!uri.match(convertToRegex(allowed));

    // Otherwise, use exact match
    return uri === allowed;
  });
};
