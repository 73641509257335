import { encodeBase64 } from "@/utils/base64";
import { OAUTH_REDIRECT_URI, OAUTH_REDIRECT_SUB } from "@/constants";

function getOauthRedirectSub() {
  // Note: move to team config when we have more whitelabels
  if (window.location.hostname === "wealthconnect.private.fin.ag") {
    return "wealthica-connect-flinks";
  }

  return OAUTH_REDIRECT_SUB;
}

function getOauthURL(provider, state) {
  const url = new URL(provider.authorizeUrl);

  url.searchParams.append("client_id", provider.clientId);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("state", state);
  url.searchParams.append("redirect_uri", OAUTH_REDIRECT_URI);

  if (provider.scopes) url.searchParams.append("scope", provider.scopes.join(" "));

  return url.href;
}

export function getRedirectOAuthURL(provider, reconnect, institution) {
  const state = encodeBase64({
    path: reconnect ? `/reconnect/${institution}` : `/connect/${provider.type}`,
    sub: getOauthRedirectSub(),
  });

  return getOauthURL(provider, state);
}

export function getPopupOAuthURL(provider, channelName) {
  const state = encodeBase64({
    path: "/oauth-callback",
    sub: getOauthRedirectSub(),
    channel_name: channelName,
  });

  return getOauthURL(provider, state);
}
