import Vue from "vue";
import { mapGetters } from "vuex";

import { localize } from "vee-validate";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { SENTRY_DSN, SENTRY_ENV } from "@/constants";

import "@/plugins/clipboard";
import "@/plugins/select";
import "@/plugins/vee-validate";
import "@/plugins/dompurify-html";

import { i18n, setI18nLanguage } from "@/i18n";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import api from "./api";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: "wealthica-connect@1.0.0",
    environment: SENTRY_ENV,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({ levels: ["error"] }),
      new Sentry.Integrations.Breadcrumbs({ fetch: true, xhr: true, console: true, dom: false }),
      new Sentry.Integrations.FunctionToString(),
      new Sentry.Integrations.GlobalHandlers({ onerror: true, onunhandledrejection: true }),
      new Sentry.Integrations.HttpContext(),
    ],

    tracesSampleRate: 1.0,
    beforeSend(event) {
      // Clean up token in URL query
      // eslint-disable-next-line no-param-reassign
      event.request.url = event.request.url.replace(new RegExp("[&;]?token=[^&;]+"), "");

      return event;
    },
  });

  api.addResponseTransform(response => {
    if (!response.ok) {
      let error = `${response.status || response.problem}`;
      if (response.originalError) {
        error += ` (${response.originalError.config.method.toLocaleUpperCase()} ${
          response.originalError.config.baseURL
        }${response.originalError.config.url})`;
      }

      Sentry.captureMessage(error);
    }
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  computed: {
    ...mapGetters("app", ["lang"]),
  },
  watch: {
    lang: {
      handler: "handleLang",
      immediate: true,
    },
  },
  methods: {
    handleLang(lang) {
      setI18nLanguage(lang);
      localize(lang);
    },
  },
  render: h => h(App),
}).$mount("#app");
