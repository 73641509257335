<template>
  <div id="app" class="pusher">
    <DefaultLayout>
      <router-view />
    </DefaultLayout>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/Default.vue";

export default {
  components: { DefaultLayout },
};
</script>

<style lang="scss">
@import "@/styles/base.scss";
@import "@/styles/fonts.scss";
@import "vue-select/src/scss/vue-select.scss";
@import "@/styles/vueselect_overrides.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;

  height: 100%;
  min-height: 100%;
}
</style>
