import { loadLocalStorage, cleanupLocalStorage } from "@/utils/localStorage";

export default function sendDataToParent(data) {
  if (!window.parent) return;

  const { origin } = loadLocalStorage();
  cleanupLocalStorage();

  window.parent.postMessage(JSON.stringify(data), origin);
}
