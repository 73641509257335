<template>
  <div class="widget">
    <div class="widget__container">
      <div v-if="closeEnabled" class="widget__close" @click="close">
        <CloseIcon class="widget__close-icon" />
      </div>
      <ErrorBoundary class="widget__content">
        <slot />
      </ErrorBoundary>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CloseIcon from "@/assets/svg/close.svg?inline";
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import redirection from "@/mixins/redirection";
import inIframe from "../utils/inIframe";

export default {
  components: { CloseIcon, ErrorBoundary },
  mixins: [redirection],
  computed: {
    ...mapGetters("app", ["loading", "theme"]),
    ...mapGetters("institutions", ["institution"]),
    closeEnabled() {
      return !["connected", "exit"].includes(this.$route.name);
    },
  },
  watch: {
    theme: {
      handler(newValue, prevValue) {
        document.body.classList.remove(`theme-${prevValue}`);
        document.body.classList.add(`theme-${newValue}`);
      },
      immediate: true,
    },
  },
  created() {
    document.body.classList.add(`mode-${inIframe() ? "iframe" : "page"}`);
  },
  methods: {
    ...mapActions("app", ["fireEvent"]),
    async close() {
      await this.fireEvent({ name: "connect flow - close" });
      this.redirectWithError(400, this.$t("connection_closed_by_the_user"), true, this.institution);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  display: flex;
  align-items: center;

  height: 100%;

  @media all and (min-width: $tablet-small) {
    padding: 2rem 1rem;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;

    cursor: pointer;
  }

  &__close-icon {
    width: 14px;
    height: 14px;
    color: #000;
    transition: 0.3s ease;
  }

  &__container {
    position: relative;

    margin: 0 auto;

    width: 100%;
    max-width: 470px;
    height: 100%;
    padding: 0 20px;
    text-align: center;
    background-color: white;
    overflow: overlay;

    @media all and (min-width: $tablet-small) {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 710px;

      box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.12);
    }
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      background-color: $gray-200;
    }
    &::-webkit-scrollbar-thumb {
      size: 3px;
      background-color: #000;
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    min-height: -webkit-fill-available;
    @media all and (min-width: $tablet-small) {
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
.theme-dark {
  background-color: #000;

  &.mode-iframe {
    // note: widget background must be transparent so original website remains visible
    background-color: rgba(16, 16, 16, 0.9);
  }

  .widget {
    color: #fff;

    &__container {
      background-color: #000;
      box-shadow: none;
    }

    &__close-icon {
      color: #fff;
    }
  }
}
</style>
