<template>
  <div class="loading">
    <div class="loading__logo-wrap">
      <div class="loading__logo">
        <img v-if="team && team.logo" :src="team.round_logo || team.logo" alt="" />
        <div v-else-if="team" class="loading__logo-letters">
          {{ team.name.charAt(0) }}
        </div>
      </div>
      <LottieAnimation v-if="theme === 'dark'" class="loading__icon" name="loading-dark" />
      <LottieAnimation v-else class="loading__icon" name="loading-white" />
      <div class="loading__logo">
        <img :src="iconUrl(provider)" :alt="provider.name" />
      </div>
    </div>

    <div class="loading__title" :class="{ 'loading__title--long': pollReplayed === 1 }">
      {{ loadingTitle }}
    </div>
    <div class="loading__text">{{ loadingText }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "@/components/ui/LottieAnimation.vue";
import providerInfo from "@/mixins/providerInfo";
import { WEALTHICA_APP_URL } from "@/constants";

export default {
  components: {
    LottieAnimation,
  },
  mixins: [providerInfo],
  props: {
    pollReplayed: {
      default: 0,
      type: Number,
    },
    customMessage: {
      default: "",
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters("app", ["theme"]),
    ...mapGetters("team", ["team"]),
    loadingTitle() {
      let title = this.$t("securely_connecting");

      if (this.customMessage) title = this.$t(this.customMessage);

      if (this.pollReplayed === 1) title = this.$t("its_taking_longer_than_usual");
      if (this.pollReplayed === 2) title = this.$t("its_taking_longer_than_usual_2");
      if (this.pollReplayed >= 3) title = this.$t("its_taking_longer_than_usual_3");

      return title;
    },
    loadingText() {
      let text = this.$t("please_wait_while_we_connect", { name: this.provider.name });

      // Custom oauth message shows up in loading title, and we don't need a small loading text
      if (this.customMessage === "waiting_for_authorization") text = "";

      // From 2nd poll onward, we show the wait message in loading title, no need for the small loading text
      if (this.pollReplayed) text = "";

      return text;
    },
  },
  methods: {
    iconUrl(provider) {
      return `${WEALTHICA_APP_URL}/images/institutions/${provider.type}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__logo-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 120px;
  }

  &__logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 1px #fff;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__logo-letters {
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: 51px;
    background-color: #000;
  }

  &__icon {
    width: 87px;
    height: 87px;
    margin: 0 28px;
  }

  &__title {
    @include title-1;

    margin-top: 30px;
    margin-bottom: 10px;

    &--long {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: auto;
    }
  }

  &__text {
    color: $gray-800;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: auto;
    padding: 20px;
  }
}
</style>

<style lang="scss">
.theme-dark {
  .loading {
    &__text {
      color: $gray-450;
    }
  }
}
</style>
