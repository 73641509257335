import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";

extend("required", required);
extend("regex", regex);

localize({
  en: {
    code: "en",
    messages: {
      required: "The {_field_} field is required",
      regex: "The {_field_} field format is invalid",
    },
  },
  fr: {
    code: "fr",
    messages: {
      required: "Le champ {_field_} est obligatoire",
      regex: "Le champ {_field_} est invalide",
    },
  },
  es: {
    code: "es",
    messages: {
      required: "El campo {_field_} es obligatorio",
      regex: "El formato del campo {_field_} no es válido",
    },
  },
  it: {
    code: "it",
    messages: {
      required: "Il campo {_field_} è richiesto",
      regex: "Il campo {_field_} non ha un formato valido",
    },
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
