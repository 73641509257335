<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="input-form"
    :name="label || $t(name)"
    :rules="rules"
  >
    <div class="input-form__labels" :class="{ 'mod-error': errors.length }">
      <label v-if="label" class="input-form__label" :for="name">{{ label }}</label>
      <span v-if="errors.length" class="input-form__error">{{ errors[0] }}</span>
    </div>
    <div class="input-form__input-wrap">
      <input
        :id="name"
        :value="value"
        class="input-form__input"
        :type="formType"
        :class="{ 'mod-error': errors.length }"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="false"
        @input="change"
      />
      <div
        v-if="type === 'password'"
        class="input-form__icon-hide-wrapper"
        @click="changePasswordVisibility"
      >
        <IconEyeHide v-if="!passwordVisible" class="input-form__icon-hide" />
        <IconEye v-else class="input-form__icon-hide" />
      </div>

      <LockIcon v-if="lockIcon" class="input-form__icon-lock" />
    </div>
  </ValidationProvider>
</template>

<script>
import LockIcon from "@/assets/svg/lock-icon.svg?inline";
import IconEye from "@/assets/svg/icon-eye.svg?inline";
import IconEyeHide from "@/assets/svg/icon-eye-hide.svg?inline";

export default {
  components: {
    LockIcon,
    IconEye,
    IconEyeHide,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lockIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      passwordVisible: false,
    };
  },
  computed: {
    formType() {
      if (this.type !== "password") return this.type;
      return this.passwordVisible ? "text" : "password";
    },
  },
  methods: {
    change(event) {
      this.$emit("change", {
        key: this.name,
        value: event.target.value,
      });
    },
    changePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-form {
  position: relative;
  display: block;
  padding-bottom: 20px;

  &__input-wrap {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__icon-lock {
    right: 10px;
  }

  &__icon-hide {
    right: 35px;
  }

  &__icon-hide-wrapper {
    cursor: pointer;
  }

  &__input {
    display: block;
    height: 44px;
    width: 100%;

    padding: 12px 10px;
    padding-right: 30px;

    background-color: $gray-100;
    border: 1px solid $gray-200;

    font-size: 16px;
    line-height: 20px;

    outline: none !important;

    &[disabled] {
      color: $gray-500;
    }
    &[type="password"] {
      padding-right: 60px;
    }
    &[type="hidden"] ~ svg {
      display: none;
    }

    &:focus {
      border-color: var(--primary-color);
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $gray-800;
  }

  &__label {
    margin-right: 10px;
    font-size: 14px;
    line-height: 17px;
  }

  &__error {
    margin-left: auto;
  }

  &__labels.mod-error {
    color: $red-400;
  }
  &__input.mod-error {
    border-color: $red-400;
  }
}
</style>

<style lang="scss">
.theme-dark {
  .input-form__input {
    color: #fff;
  }
  .input-form__label {
    color: $gray-450;
  }
}
</style>
