import Vue from "vue";
import VueDOMPurifyHTML from "vue-dompurify-html";

Vue.use(VueDOMPurifyHTML, {
  hooks: {
    afterSanitizeAttributes: node => {
      // Set all elements owning target to target=_blank
      if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener");
      }
    },
  },
});
