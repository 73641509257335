<template>
  <div class="security-question">
    <div class="security-question__notice">
      {{ $t("security_answer_required", { provider: provider.name }) }}
      {{ $t("we_do_not_store_answer") }}
    </div>
    <div class="security-question__message">{{ syncError.message }}</div>
    <div class="security-question__field">
      <div v-if="securityQuestionOptions" class="security-question__dropdown">
        <div
          class="security-question__dropdown-value"
          :class="{ 'mod-selected': !!securityAnswer, 'mod-disabled': loading }"
          @click.stop="toggleDropdown"
        >
          {{ securityAnswer || $t("select_your_answer") }}
          <ArrowDownIcon
            class="security-question__dropdown-value-icon"
            :class="{ 'mod-rotate': dropdownVisible }"
          />
        </div>

        <div v-if="dropdownVisible" class="security-question__dropdown-list">
          <div
            v-for="(option, index) in securityQuestionOptions"
            :key="index"
            class="security-question__dropdown-item"
            :class="{ 'mod-active': securityAnswer === option }"
            @click="setAnswer(option)"
          >
            {{ option }}
          </div>
        </div>
        <FormInput
          :value="securityAnswer"
          name="security_answer"
          type="hidden"
          :rules="{ required: true }"
        />
      </div>

      <FormInput
        v-else
        :value="securityAnswer"
        name="security_answer"
        type="text"
        :rules="{ required: true }"
        :placeholder="$t('type_your_answer')"
        class="security-question__input"
        :disabled="loading"
        @change="({ value }) => setAnswer(value)"
      />
    </div>
  </div>
</template>

<script>
import FormInput from "@/components/ui/FormInput.vue";
import ArrowDownIcon from "@/assets/svg/arrow-down.svg?inline";

export default {
  components: { FormInput, ArrowDownIcon },
  props: {
    provider: {
      type: Object,
      required: true,
    },
    securityAnswer: {
      type: String,
      required: true,
    },
    syncError: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      dropdownVisible: false,
    };
  },
  computed: {
    securityQuestionOptions() {
      return this.syncError.options;
    },
  },
  mounted() {
    window.addEventListener("click", this.hideDropdown);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.hideDropdown);
  },
  methods: {
    setAnswer(option) {
      this.$emit("securityAnswerChange", option);
    },
    hideDropdown() {
      if (this.dropdownVisible) this.dropdownVisible = false;
    },
    toggleDropdown() {
      if (this.loading) return;
      this.dropdownVisible = !this.dropdownVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.security-question {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__notice {
    margin-top: 15px;
    margin-bottom: auto;
    font-size: 0.875rem;
    color: $gray-800;
    line-height: 18px;
  }

  &__disclaimer {
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25;
    color: $gray-300;
  }

  &__message {
    margin: 0 auto 0.75rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 18px;
  }

  &__field {
    width: 100%;
    margin-bottom: auto;
  }

  &__dropdown {
    position: relative;
  }

  &__dropdown-value {
    display: block;
    height: 44px;
    width: 100%;

    padding: 12px 10px;
    padding-right: 30px;

    color: $gray-600;

    background-color: transparent;
    border: 1px solid $gray-200;

    font-size: 16px;
    line-height: 20px;

    outline: none !important;

    &:focus {
      border-color: var(--primary-color);
    }

    &.mod-selected {
      color: #000;
      background-color: $gray-100;
      font-weight: 600;
    }
  }

  &__dropdown-value-icon {
    position: absolute;
    color: #000;
    top: 1.125rem;
    right: 1rem;

    &.mod-rotate {
      transform: rotate(180deg);
    }
  }

  &__dropdown-list {
    position: absolute;
    z-index: 20;

    width: 100%;
    max-height: 12rem;

    margin-top: 0.75rem;
    padding: 0.5rem 1rem;

    overflow-y: auto;

    background-color: #fff;
    box-shadow: 0 10px 26px 0 rgba(77, 64, 105, 0.28);
    border-radius: 11px;
  }

  &__dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.75rem;

    color: $gray-900;
    font-weight: 500;
    font-size: 0.875rem;

    transition: 0.3s ease;
    cursor: pointer;

    &:hover,
    &.mod-active {
      background-color: $gray-100;
      color: #000;
    }
  }
}
</style>
