import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import team from "./team";
import institutions from "./institutions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { app, team, institutions },
});
