<template>
  <div class="wrapper">
    <div v-if="!loading" id="connect_institution_prod" class="connect-institution">
      <div class="connect-institution__logo-wrapper">
        <div class="connect-institution__logo-client" :class="{ mod_bg: team && !team.logo }">
          <img v-if="team && team.logo" :src="team.roundLogo || team.logo" alt="" />
          <div v-else-if="team" class="connect-institution__logo-letters">
            {{ team.name.charAt(0) }}
          </div>
        </div>
        <CompanyIcon v-if="team && !team.is_whitelabel" class="connect-institution__logo-app" />
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="team && (team.is_whitelabel || (team.name && team.name.includes('Wealthica')))"
        class="connect-institution__description"
        v-html="$t('link_your_institutions')"
      />
      <p
        v-else
        class="connect-institution__description"
        v-html="$t('connect_institution_description', { team: teamName, companyName })"
      />

      <!-- eslint-enable vue/no-v-html -->

      <div class="connect-institution__advantage">
        <div class="connect-institution__advantage-text">
          <IconSecure class="connect-institution__advantage-icon" />
          <div class="connect-institution__advantage-title">{{ $t("secure") }}</div>
          <div class="connect-institution__advantage-description">
            {{ $t("secure_description") }}
          </div>
        </div>
        <div class="connect-institution__advantage-text">
          <PrivateIcon class="connect-institution__advantage-icon" />
          <div class="connect-institution__advantage-title">{{ $t("private") }}</div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="connect-institution__advantage-description"
            v-html="$t('private_description', { name: team && team.name })"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="connect-institution__footer"
        v-html="$t('policy_and_terms_agree', { policyLink, termsLink, companyName })"
      />
      <!-- eslint-enable vue/no-v-html -->

      <div
        v-if="$route.name !== 'reconnect-institution' && providerIsPreselected && !provider.name"
        class="error"
      >
        {{ $t("invalid_provider") }}
      </div>
      <div v-else-if="error" class="error">
        {{ error }}
      </div>
      <button v-else-if="validated" class="connect-institution__continue" @click="nextStep">
        {{ $t("continue") }}
      </button>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import IconSecure from "@/assets/svg/icon-secure.svg?inline";
import PrivateIcon from "@/assets/svg/icon-private.svg?inline";
import CompanyIcon from "@/assets/svg/logo.svg?inline";
import Loading from "@/components/ui/Loading.vue";
import providerInfo from "@/mixins/providerInfo";

export default {
  components: {
    IconSecure,
    PrivateIcon,
    Loading,
    CompanyIcon,
  },
  mixins: [providerInfo],
  props: {
    error: {
      type: String,
      default: "",
    },
    validated: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("app", ["loading", "providerIsPreselected", "isBackFromOAuth"]),
    ...mapGetters("team", ["teamName", "team", "policyLink", "termsLink", "companyName"]),
  },
  watch: {
    validated() {
      // Skip first screen if team has skip_first_screen flag right after loading all the data what
      // happens only when validated set to true
      if (
        this.team &&
        this.validated &&
        !this.loading &&
        this.team.skip_first_screen &&
        !this.isBackFromOAuth
      ) {
        this.nextStep();
      }
    },
  },
  methods: {
    ...mapActions("app", ["goToProvideCredentialsStep", "goToSelectProviderStep"]),
    nextStep() {
      if (this.$route.name === "reconnect-institution" || this.$route.params.provider) {
        this.goToProvideCredentialsStep();
      } else {
        this.goToSelectProviderStep();
      }
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  height: 100%;
}

.connect-institution {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__animation {
    position: relative;
    z-index: 1;
    margin: auto -10px;
    width: 140px;
    height: 80px;
  }

  &__logo-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding-top: 20px;

    @media all and (min-width: $tablet-small) {
      padding-top: 50px;
    }
  }

  &__logo-client,
  &__logo-app {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border: 2px solid #fff;
  }

  &__logo-client {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 5px;

    &.mod_bg {
      background: #000;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: contain;
    }
  }

  &__logo-letters {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }

  &__logo-app {
    margin-left: -20px;
    color: var(--primary-color);
  }

  &__description {
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;

    strong {
      font-weight: 600;
    }
  }

  &__advantage {
    text-align: left;
    max-width: $contentMaxWidth;
    margin: auto;
    padding: 28px 0;
    overflow: hidden;
    background-color: $gray-150;
    border-radius: 10px;
  }

  &__advantage-icon {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &__advantage-text {
    position: relative;
    margin-left: 25px;
    padding-left: 34px;
    font-size: 14px;
    line-height: 20px;

    &:first-of-type {
      margin-bottom: 26px;
    }
  }

  &__advantage-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }

  &__advantage-description {
    margin: 0 20px 0 0;
    font-size: 14px;
    line-height: 20px;

    @media all and (min-width: $tablet-small) {
      margin: 0 40px 0 0;
    }
  }

  &__continue {
    @include btn-default;

    max-width: $contentMaxWidth;
    margin-bottom: 40px;
  }

  &__footer {
    max-width: $contentMaxWidth;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;

    a {
      color: inherit;
      text-decoration: underline;
      transition: 0.3s ease;

      &:hover {
        color: $gray-600;
      }
    }
  }
}
</style>

<style lang="scss">
.theme-dark {
  .connect-institution__continue {
    @include btn-default-dark;
  }

  .connect-institution____logo-app {
    border: 1px solid #fff;
  }
}
</style>
