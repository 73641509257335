export default function composeUrl(redirectURI, params = {}) {
  // Either /exit or /connected
  if (redirectURI.indexOf("/") === 0) {
    const queryString = new URLSearchParams(params).toString();
    return `${redirectURI}?${queryString}`;
  }

  // Client redirect URI, need to handle URIs with query string
  const url = new URL(redirectURI);
  Object.keys(params).forEach(key => {
    url.searchParams.append(key, params[key]);
  });

  return url.toString();
}
