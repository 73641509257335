import { mapGetters } from "vuex";

// todo: move provider logic to store
export default {
  computed: {
    ...mapGetters("institutions", ["institution", "providers"]),
    ...mapGetters("team", ["companyName"]),
    providerName() {
      return this.$route.params.provider || (this.institution ? this.institution.type : "");
    },
    provider() {
      return this.providers.find(item => item.type === this.providerName.toLowerCase()) || {};
    },
    credentials() {
      return this.provider.credentials || ["username", "password"];
    },
    isOauth() {
      return this.provider.oauth;
    },
    instructions() {
      const { instructions, name } = this.provider;

      if (instructions) return instructions[this.lang] || instructions.en;
      if (this.isOauth)
        return this.$t("oauth_instructions", { provider: name, companyName: this.companyName });

      return this.$t("enter_credentials_to_login_to_name", { provider: name });
    },
  },
};
