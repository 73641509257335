import API from "@/api";

const initialState = {
  team: null,
};
const moduleGetters = {
  team: state => state.team,
  teamName: (state, getters) => (getters.team ? getters.team.name : ""),
  teamFeatures: (state, getters) =>
    getters.team && getters.team.features ? getters.team.features : [],
  redirectURIs: (state, getters) => (getters.team ? getters.team.redirect_uris : []),
  useWideLogo: (state, getters) => !!(getters.team && getters.isFlinks),
  isFlinks: (state, getters) =>
    (getters.team && getters.team.slug?.includes("flinks")) ||
    window.location.hostname === "wealthconnect.private.fin.ag",
  policyLink: () => {
    return "https://wealthica.com/terms/";
  },
  termsLink: () => {
    return "https://wealthica.com/privacy-policy/";
  },
  companyName: (state, getters) => {
    if (getters.team && getters.team.is_whitelabel) return getters.team.name;

    return "Wealthica";
  },
};

const mutations = {
  STORE_TEAM(state, team) {
    state.team = team;
  },
};

const actions = {
  async fetchTeam({ commit, dispatch }, clientId) {
    dispatch("app/setLoading", true, { root: true });
    const response = await API.get("/teams/info", { client_id: clientId });
    dispatch("app/setLoading", false, { root: true });

    if (!response.ok) throw response.originalError;

    commit("STORE_TEAM", response.data);
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions,
  namespaced: true,
};
