<template>
  <div>
    <slot />
  </div>
</template>

<script>
import redirection from "@/mixins/redirection";

export default {
  mixins: [redirection],
  errorCaptured(error) {
    this.error = error;
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    errorMessage() {
      return this.error.message;
    },
  },
  watch: {
    error() {
      if (this.error && this.errorMessage) {
        this.redirectWithError(400, this.errorMessage);
      }
    },
  },
};
</script>
