<template>
  <div class="header">
    <IconArrowBack v-if="!hideBack" class="header__btn-back" @click="prevStep" />
    <div class="header__logo-wrap" :class="{ 'mod_wide-logo': useWideLogo }">
      <div class="header__logo" :class="{ 'mod_wide-logo': useWideLogo }">
        <img v-if="team && team.logo" :src="team.logo" alt="" />
        <div v-else-if="team" class="header__logo-letters">
          {{ team.name.charAt(0) }}
        </div>
      </div>
      <span v-if="team && !team.is_whitelabel">+</span>
      <div v-if="team && !team.is_whitelabel" class="header__logo">
        <CompanyIcon />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconArrowBack from "@/assets/svg/arrow-back.svg?inline";
import CompanyIcon from "@/assets/svg/logo.svg?inline";
import providerInfo from "@/mixins/providerInfo";

export default {
  components: { IconArrowBack, CompanyIcon },
  mixins: [providerInfo],
  props: {
    hideBack: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("team", ["team", "useWideLogo", "isFlinks"]),
  },
  mounted() {
    this.setFavicon();
  },
  methods: {
    prevStep() {
      this.$emit("prevStep");
    },
    setFavicon() {
      const faviconEl = document.querySelector("#favicon");
      if (this.isFlinks) {
        faviconEl.href = "/assets/flinks-favicon-32x32.png";
      } else {
        faviconEl.href = "/assets/favicon.png";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: $headerHeight;

  &__btn-back {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 10px;
    height: 16px;
    padding: 10px;
    box-sizing: initial;
    cursor: pointer;

    &:hover {
      color: $gray-600;
    }
  }

  &__logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    &.mod_wide-logo {
      opacity: 1;
    }

    span {
      margin: 0 15px;
      margin-bottom: 3px;
      font-size: 22px;
    }
  }

  &__logo {
    width: 35px;
    height: 35px;
    box-shadow: 0 0 0 1px #fff;
    color: var(--primary-color);

    border-radius: 50%;
    overflow: hidden;

    &.mod_wide-logo {
      width: 100px;
      border-radius: 0;

      img {
        object-fit: contain;
        height: 100%;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__logo-letters {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
    background-color: #000;
  }
}
</style>
