export function loadLocalStorage() {
  return {
    state: localStorage.getItem("client_state"),
    redirectURI: localStorage.getItem("redirect_uri"),
    origin: localStorage.getItem("origin"),
    lang: localStorage.getItem("lang"),
  };
}

export function cleanupLocalStorage() {
  localStorage.removeItem("client_state");
  localStorage.removeItem("token");
  localStorage.removeItem("client_id");
  localStorage.removeItem("redirect_uri");
  localStorage.removeItem("origin");
  localStorage.removeItem("lang");
}
