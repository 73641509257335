<template>
  <div ref="animation" />
</template>

<script>
import lottie from "lottie-web";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.animation,
      renderer: "svg",
      loop: this.loop,
      autoplay: true,
      path: `/assets/lottie/${this.name}.json`,
    });
  },
};
</script>
