import composeUrl from "@/utils/composeUrl";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["clientState", "redirectURI", "selectedProvider"]),
  },
  methods: {
    redirectWithError(code, message, force, institution) {
      const params = {
        error_type: code,
        message,
        provider: this.selectedProvider?.type,
      };

      if (force) params.force = true;
      if (institution) params.institution = institution.id;

      if (this.clientState) params.state = this.clientState;

      this.$router.push(composeUrl("/exit", params));
    },
    redirectWithInstitution(institution) {
      const params = {
        institution: institution.id,
        code: institution.id, // alias to institution.id for oauth compliance
        provider: institution.type || this.selectedProvider?.type,
      };

      if (this.clientState) params.state = this.clientState;

      this.$router.push(composeUrl("/connected", params));
    },
  },
};
