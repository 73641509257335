<template>
  <div class="exit">
    <HeaderTop hide-back />
    <div class="exit__logo-wrap">
      <div v-if="!hideLogo" class="exit__logo">
        <img v-if="team && team.logo" :src="team.round_logo || team.logo" alt="" />
        <div v-else-if="team" class="exit__logo-letters">
          {{ team.name.charAt(0) }}
        </div>
      </div>
      <LottieAnimation class="exit__icon" name="error" :loop="false" />
      <div v-if="!hideLogo" class="exit__logo">
        <img :src="iconUrl(selectedProvider)" :alt="selectedProvider && selectedProvider.name" />
      </div>
    </div>

    <div class="exit__title">{{ $t("connection_failed") }}</div>

    <div v-if="errorMessage.length" class="exit__message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import LottieAnimation from "@/components/ui/LottieAnimation.vue";
import sendDataToParent from "@/utils/sendDataToParent";
import { cleanupLocalStorage } from "@/utils/localStorage";
import composeUrl from "@/utils/composeUrl";
import { mapGetters } from "vuex";
import providerInfo from "@/mixins/providerInfo";
import { WEALTHICA_APP_URL } from "@/constants";
import HeaderTop from "@/components/ui/HeaderTop.vue";

export default {
  components: {
    LottieAnimation,
    HeaderTop,
  },
  mixins: [providerInfo],
  computed: {
    ...mapGetters("app", ["redirectURI", "selectedProvider"]),
    ...mapGetters("team", ["team", "isFlinks"]),
    errorMessage() {
      return this.$route.query.message;
    },
    force() {
      return this.$route.query.force;
    },
    hideLogo() {
      return this.isFlinks;
    },
  },
  created() {
    if (this.force) this.next();
    // wait some time (2 seconds) before close widget or redirect
    else this.timeout = setTimeout(this.next, 2000);
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
  methods: {
    next() {
      return this.redirectURI ? this.onRedirectMode() : this.onWidgetMode();
    },
    onRedirectMode() {
      cleanupLocalStorage();
      window.location.href = composeUrl(this.redirectURI, { ...this.$route.query });
    },
    onWidgetMode() {
      sendDataToParent({
        wealthica: true,
        event: this.force ? "close" : "error",
        error: this.$route.query,
      });
    },
    iconUrl(provider) {
      const providerName = (provider && provider.name && provider.name.toLowerCase()) || undefined;
      return `${WEALTHICA_APP_URL}/images/institutions/${providerName}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: auto;
}
.exit {
  max-width: 252px;
  margin: 0 auto;

  &__main-logo {
    margin-top: 44px;
    margin-bottom: 133px;
  }

  &__logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
  }

  &__logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 1px #fff;
    color: var(--primary-color);

    img {
      width: 100%;
    }
  }

  &__logo-letters {
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: 51px;
    background-color: #000;
  }

  &__icon {
    width: 87px;
    height: 87px;
    margin: 0 28px;
  }
  &__title {
    @include title-1;

    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__message {
    color: $gray-800;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: auto;
  }
}
</style>

<style lang="scss">
.theme-dark {
  .exit {
    &__message {
      color: $gray-450;
    }
  }
}
</style>
