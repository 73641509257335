export default function openPopup(url, title, width, height) {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;
  let options = "";
  options += `,width=${width}`;
  options += `,height=${height}`;
  options += `,top=${top}`;
  options += `,left=${left}`;
  options += ",scrollbars=yes";
  return window.open(url, title, options);
}
