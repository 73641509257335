import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  DEFAULT_THEME,
  THEMES,
  PROVIDERS_PER_LINE_OPTIONS,
  DEFAULT_PROVIDERS_PER_LINE,
  STEP_CONNECT_INSTITUTION,
  STEP_SELECT_PROVIDER,
  STEP_PROVIDE_CREDENTIALS,
} from "@/constants";
import API from "../api";

const initialState = {
  lang: localStorage.getItem("lang") || DEFAULT_LANGUAGE,
  theme: localStorage.getItem("theme") || DEFAULT_THEME,
  features: localStorage.getItem("features"),
  providersPerLine: "2",
  loggedIn: false,
  connectionStep: STEP_CONNECT_INSTITUTION,
  loading: false,
  providerIsPreselected: false,
  clientId: localStorage.getItem("client_id") || "",
  clientState: localStorage.getItem("client_state") || "",
  token: localStorage.getItem("token") || "",
  origin: localStorage.getItem("origin") || "",
  redirectURI: localStorage.getItem("redirect_uri") || "",
  isBackFromOAuth: false,
  securityAnswerRequested: false,
  providerGroups: ["core"],
  selectedProvider: {},
};
const moduleGetters = {
  lang: state => state.lang,
  theme: state => state.theme,
  features: state => state.features,
  providersPerLine: state => state.providersPerLine,
  loggedIn: state => state.loggedIn,
  connectionStep: state => state.connectionStep,
  loading: state => state.loading,
  providerIsPreselected: state => state.providerIsPreselected,
  clientId: state => state.clientId,
  clientState: state => state.clientState,
  token: state => state.token,
  origin: state => state.origin,
  redirectURI: state => state.redirectURI,
  isBackFromOAuth: state => state.isBackFromOAuth,
  securityAnswerRequested: state => state.securityAnswerRequested,
  providerGroups: state => state.providerGroups,
  selectedProvider: state => state.selectedProvider,
};

const mutations = {
  STORE_LANGUAGE(state, lang) {
    state.lang = lang;
  },
  STORE_THEME(state, theme) {
    state.theme = theme;
  },
  STORE_FEATURES(state, features) {
    state.features = features;
  },
  STORE_PROVIDERS_PER_LINE(state, providersPerLine) {
    state.providersPerLine = providersPerLine;
  },
  STORE_LOGGED_IN(state, loggedIn) {
    state.loggedIn = loggedIn;
  },
  STORE_CONNECTION_STEP(state, step) {
    state.connectionStep = step;
  },
  STORE_LOADING(state, loading) {
    state.loading = loading;
  },
  STORE_PROVIDER_PRESELECTED(state, preselected) {
    state.providerIsPreselected = preselected;
  },
  STORE_CLIENT_ID(state, clientId) {
    state.clientId = clientId;
  },
  STORE_CLIENT_STATE(state, clientState) {
    state.clientState = clientState;
  },
  STORE_TOKEN(state, token) {
    state.token = token;
  },
  STORE_ORIGIN(state, origin) {
    state.origin = origin;
  },
  STORE_REDIRECT_URI(state, redirectURI) {
    state.redirectURI = redirectURI;
  },
  STORE_IS_BACK_FROM_OAUTH(state, isBackFromOAuth) {
    state.isBackFromOAuth = isBackFromOAuth;
  },
  STORE_SECURITY_ANSWER_REQUESTED(state, securityAnswerRequested) {
    state.securityAnswerRequested = securityAnswerRequested;
  },
  STORE_PROVIDER_GROUPS(state, providerGroups) {
    state.providerGroups = providerGroups;
  },
  STORE_SELECTED_PROVIDER(state, selectedProvider) {
    state.selectedProvider = selectedProvider;
  },
};

const actions = {
  updateLanguage({ commit }, lang) {
    const allowedLang = LANGUAGES.includes(lang) ? lang : DEFAULT_LANGUAGE;

    localStorage.setItem("lang", allowedLang);
    commit("STORE_LANGUAGE", allowedLang);
  },
  updateTheme({ commit }, theme) {
    const allowedTheme = THEMES.includes(theme) ? theme : DEFAULT_THEME;

    localStorage.setItem("theme", allowedTheme);
    commit("STORE_THEME", allowedTheme);
  },
  updateFeatures({ commit }, features) {
    localStorage.setItem("features", features);
    commit("STORE_FEATURES", features);
  },
  updateProvidersPerLine({ commit }, theme) {
    const allowedCount = PROVIDERS_PER_LINE_OPTIONS.includes(theme)
      ? theme
      : DEFAULT_PROVIDERS_PER_LINE;

    commit("STORE_PROVIDERS_PER_LINE", allowedCount);
  },
  updateProviderGroups({ commit }, providerGroups) {
    commit("STORE_PROVIDER_GROUPS", providerGroups?.split(",") || ["core"]);
  },
  updateLoggedIn({ commit }, loggedIn) {
    commit("STORE_LOGGED_IN", loggedIn);
  },
  goToConnectInstitutionStep({ commit }) {
    commit("STORE_CONNECTION_STEP", STEP_CONNECT_INSTITUTION);
  },
  goToSelectProviderStep({ commit }) {
    commit("STORE_CONNECTION_STEP", STEP_SELECT_PROVIDER);
  },
  goToProvideCredentialsStep({ commit }) {
    commit("STORE_CONNECTION_STEP", STEP_PROVIDE_CREDENTIALS);
  },
  setLoading({ commit }, loading) {
    commit("STORE_LOADING", loading);
  },
  setPreselected({ commit }, preselected) {
    commit("STORE_PROVIDER_PRESELECTED", preselected);
  },
  setClientId({ commit }, clientId) {
    commit("STORE_CLIENT_ID", clientId);
  },
  setClientState({ commit }, clientState) {
    commit("STORE_CLIENT_STATE", clientState);
  },
  setToken({ commit }, token) {
    commit("STORE_TOKEN", token);
    API.setHeaders({ Authorization: `Bearer ${token}` });
  },
  setOrigin({ commit }, origin) {
    commit("STORE_ORIGIN", origin);
  },
  setRedirectURI({ commit }, redirectURI) {
    commit("STORE_REDIRECT_URI", redirectURI);
  },
  setIsBackFromOAuth({ commit }, isBackFromOAuth) {
    commit("STORE_IS_BACK_FROM_OAUTH", isBackFromOAuth);
  },
  setSecurityAnswerRequested({ commit }, securityAnswerRequested) {
    commit("STORE_SECURITY_ANSWER_REQUESTED", securityAnswerRequested);
  },
  setSelectedProvider({ commit }, selectedProvider) {
    commit("STORE_SELECTED_PROVIDER", selectedProvider);
  },
  async fireEvent({ getters }, { name, ...data }) {
    const provider = getters.selectedProvider
      ? {
          type: getters.selectedProvider.type,
          name: getters.selectedProvider.name,
        }
      : {};

    try {
      await Promise.any([
        new Promise(resolve => {
          API.post("/track/wealthica-connect", {
            eventName: name,
            ...provider,
            ...data,
          }).then(() => {
            resolve();
          });
        }),
        new Promise((resolve) => {
          // hack to limit max tracking wait time to 1s
          setTimeout(() => {
            resolve();
          }, 1000);
        }),
      ]);
    } catch (err) {
      // silently fail tracking calls.
    }
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions,
  namespaced: true,
};
