export default origin => {
  // * is allowed but not recommended
  if (origin === "*") return true;

  let url;

  try {
    url = new URL(origin);
  } catch (error) {
    return false;
  }

  return (
    // Should contain http or https protocol
    (url.protocol === "http:" || url.protocol === "https:") &&
    // Should not contain search string
    !url.search &&
    // Should not contain pathname
    url.pathname === "/"
  );
};
