import API from "@/api";

const initialState = {
  institution: null,
  providers: [],
};

const moduleGetters = {
  institution: state => state.institution,
  providers: state => state.providers,
};

const mutations = {
  STORE_INSTITUTION(state, institution) {
    state.institution = institution;
  },
  STORE_PROVIDERS(state, providers) {
    state.providers = providers;
  },
};

const actions = {
  async fetchProviders({ rootGetters, commit, dispatch }) {
    dispatch("app/setLoading", true, { root: true });

    const response = await API.get("/providers", {
      group: rootGetters["app/providerGroups"].join(","),
      format: "array",
    });
    dispatch("app/setLoading", false, { root: true });

    // apisause does not consider 304 to be 'ok', thus the separate check
    if (!response.ok && response.status !== 304) return response;

    commit("STORE_PROVIDERS", response.data);
    return response.data;
  },

  async fetchInstitution({ commit, dispatch }, id) {
    dispatch("app/setLoading", true, { root: true });
    const response = await API.get(`/institutions/${id}`);
    dispatch("app/setLoading", false, { root: true });

    // apisause does not consider 304 to be 'ok', thus the separate check
    if (!response.ok && response.status !== 304) return response;

    commit("STORE_INSTITUTION", response.data);
    return response.data;
  },

  async createInstitution({ commit, dispatch }, institution) {
    dispatch("app/setLoading", true, { root: true });
    const response = await API.post("/institutions", institution);
    dispatch("app/setLoading", false, { root: true });

    // apisause does not consider 304 to be 'ok', thus the separate check
    if (!response.ok && response.status !== 304) return response;

    commit("STORE_INSTITUTION", response.data);

    return response.data;
  },

  async updateInstitution({ commit, dispatch }, institution) {
    dispatch("app/setLoading", true, { root: true });
    const response = await API.put(`/institutions/${institution._id}`, institution);
    dispatch("app/setLoading", false, { root: true });

    if (!response.ok) return response;

    commit("STORE_INSTITUTION", response.data);

    return response.data;
  },

  async pollInstitution({ dispatch }, { id, v }) {
    dispatch("app/setLoading", true, { root: true });
    const response = await API.get(`/institutions/${id}/poll`, { v });
    dispatch("app/setLoading", false, { root: true });

    // apisause does not consider 304 to be 'ok', thus the separate check
    if (!response.ok && response.status !== 304) {
      return response;
    }

    return response.data;
  },
  resetInstitution({ commit }) {
    commit("STORE_INSTITUTION", null);
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
  namespaced: true,
};
