/* Language codes for locale messages */
export const LANGUAGES = ["en", "fr", "es", "it"];
export const DEFAULT_LANGUAGE = "en";

/* Themes */
export const THEMES = ["light", "dark"];
export const DEFAULT_THEME = "light";

/* Providers per line config */
export const PROVIDERS_PER_LINE_OPTIONS = ["1", "2"];
export const DEFAULT_PROVIDERS_PER_LINE = "2";

export const API_URL = process.env.VUE_APP_API_URL || "https://api.wealthica.com";
export const WEALTHICA_APP_URL =
  process.env.VUE_APP_WEALTHICA_APP_URL || "https://app.wealthica.com";

export const STEP_CONNECT_INSTITUTION = "connect-institution";
export const STEP_SELECT_PROVIDER = "select-provider";
export const STEP_PROVIDE_CREDENTIALS = "provide-credentials";

export const OAUTH_REDIRECT_URI =
  process.env.VUE_APP_OAUTH_REDIRECT_URI || "https://api.wealthica.com/oauth/redirect";
export const OAUTH_REDIRECT_SUB = process.env.VUE_APP_OAUTH_REDIRECT_SUB || "wealthica-connect";

/* Pusher socket url */
export const PUSHER_API_KEY = process.env.VUE_APP_PUSHER_API_KEY;

/* Sentry */
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
export const SENTRY_ENV = process.env.VUE_APP_SENTRY_ENV || "production";

export const MAX_TOKEN_LIFETIME = process.env.VUE_APP_MAX_TOKEN_LIFETIME || 600;
