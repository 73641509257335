<template>
  <div class="providers-wrapper">
    <HeaderTop :hide-back="team && team.skip_first_screen" @prevStep="prevStep" />
    <div class="providers" :class="`providers-per-line-${providersPerLine}`">
      <div class="providers__title">{{ $t("connect_your_bank_or_brokerage") }}</div>

      <div class="providers__search">
        <input
          v-model="searchQuery"
          class="providers__search-input"
          type="text"
          :placeholder="$t('search_for_a_bank_or_brokerage')"
        />
        <SearchIcon class="providers__search-icon" />
      </div>

      <div v-if="filteredProviders.length" class="providers__list">
        <div
          v-for="(provider, key) in filteredProviders"
          :key="key"
          class="providers__item"
          @click="selectProvider(provider)"
        >
          <img :src="iconUrl(provider)" :alt="provider.name" class="providers__item-logo" />
          <div class="providers__item-text">{{ provider.name }}</div>
        </div>
      </div>
      <div v-else class="providers__no-results">
        {{ $t("no_results_found") }}.
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Fuse from "fuse.js";
import SearchIcon from "@/assets/svg/search.svg?inline";
import { WEALTHICA_APP_URL } from "@/constants";
import HeaderTop from "@/components/ui/HeaderTop.vue";

export default {
  components: { SearchIcon, HeaderTop },
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters("institutions", ["providers"]),
    ...mapGetters("app", ["providersPerLine"]),
    ...mapGetters("team", ["team"]),
    allowedProvidersList() {
      const { providers } = this.$route.query;
      return providers ? providers.split(",") : null;
    },
    availableProviders() {
      return this.providers.filter(item => {
        return (
          (!this.allowedProvidersList ||
            !this.allowedProvidersList.length ||
            this.allowedProvidersList.includes(item.type)) &&
          !item.unpublished
        );
      });
    },
    filteredProviders() {
      if (!this.searchQuery) return this.availableProviders;

      if (!this.fuse) return [];

      return this.fuse.search(this.searchQuery).map(({ item }) => item);
    },
  },
  created() {
    // Process right away if providers list is already fetched
    if (this.providers.length) this.initFuse();
    else {
      // Otherwise, watch for the list and process when available
      this.unwatchProviders = this.$watch("providers", (newVal, oldVal) => {
        if (!oldVal.length && newVal.length) {
          this.initFuse();
          this.unwatchProviders();
        }
      });
    }
  },
  methods: {
    ...mapActions("app", [
      "goToProvideCredentialsStep",
      "goToConnectInstitutionStep",
      "setSelectedProvider",
      "fireEvent",
    ]),
    selectProvider(provider) {
      this.setSelectedProvider(provider);

      this.fireEvent({ name: "connect flow - provider selected" });

      this.$router.push({ path: `/connect/${provider.type}`, query: this.$route.query });
    },
    initFuse() {
      this.fuse = new Fuse(this.availableProviders, {
        keys: ["name"],
        threshold: 0.3,
        shouldSort: false,
      });
    },
    prevStep() {
      this.goToConnectInstitutionStep();
    },
    iconUrl(provider) {
      return `${WEALTHICA_APP_URL}/images/institutions/${provider.type}.png`;
    },
  },
};
</script>

<style lang="scss">
.providers-wrapper {
  height: inherit;
}
.providers {
  max-width: $contentMaxWidth;
  width: 100%;
  height: calc(100% - #{$headerHeight});
  margin: 0 auto;
  padding-top: 1px;

  &__title {
    @include title-1;
    text-align: left;

    font-size: 22px;
    line-height: 25px;
    height: 50px;
    margin-bottom: 5px;
    text-align: center;
  }

  &__search {
    position: relative;
    margin: 0 auto;
  }

  &__search-input {
    width: 100%;

    height: 60px;

    background-color: transparent;
    border: 1px solid $gray-200;
    outline: none;

    padding: 12px;
    padding-left: 44px;

    font-family: inherit;
    font-size: 16px;
    font-weight: 600;

    @include placeholder {
      color: $gray-450;
    }
  }

  &__search-icon {
    position: absolute;

    top: 50%;
    left: 15px;
    transform: translateY(-50%);

    width: 16px;
    height: 16px;
  }

  &__filter {
    text-align: left;
  }

  &__filter-btn {
    padding: 0;
    padding-bottom: 5px;
    margin-right: 14px;
    font-size: 16px;
    font-weight: 500;
    color: $gray-400;
    outline: 0;
    border: 0;
    border-bottom: 2px solid transparent;
    background: transparent;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    &:hover {
      color: $gray-800;
    }
    &.active {
      color: #000;
      border-bottom-color: #000;
      pointer-events: none;
    }
  }

  &__list {
    display: grid;
    max-height: calc(100% - 135px);
    overflow: hidden;
    overflow-y: auto;
    margin: 0 auto;
    margin-top: 10px;
    margin-right: -10px;
    padding-right: 14px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 0.75rem;

    @media all and (min-width: $tablet-small) {
      max-width: calc(#{$contentMaxWidth} + 10px);
    }
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-track {
      background-color: $gray-200;
    }
    &::-webkit-scrollbar-thumb {
      size: 3px;
      background-color: #000;
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;

    padding: 20px 0;

    border-bottom: 1px solid $gray-200;

    cursor: pointer;

    &:hover {
      border-bottom-color: black;

      svg {
        animation: arrowHover 0.6s 1;
      }
    }
  }

  &__item-beta {
    display: inline-block;
    padding: 1px 3px 0;
    background-color: $gray-200;
    font-size: 12px;
    font-weight: 600;
    color: #727272;
  }

  @keyframes arrowHover {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-3px);
    }
    60% {
      transform: translateX(-3px);
    }
    100% {
      transform: translateX(0);
    }
  }

  &__item-logo {
    display: block;
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  &__item-text {
    display: inline-block;
    max-width: calc(100% - 58px);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }

  &__item-arrow {
    flex-shrink: 0;
    margin-left: auto;
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    height: calc(100% - 290px);
    margin-top: 70px;
    color: $gray-900;
    font-weight: 500;
  }

  &__no-results-text {
    max-width: 200px;
    margin: 0 auto;
  }

  &.providers-per-line-1 {
    .providers__list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0;
    }

    .providers__item-logo {
      margin-right: 0.75rem;
    }

    .providers__item-text {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__via-wallet-connect {
    @include btn-default;

    margin-top: auto;
  }
}
</style>

<style lang="scss">
.theme-dark {
  .providers__list {
    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
      &:hover {
        background-color: $gray-600;
      }
    }
  }
  .providers__item {
    border-color: $gray-900;
  }

  .providers__item-text {
    color: #fff;
  }

  .providers__item-logo {
    background-color: #fff;

    // to fix white line on border radius
    will-change: transform;
  }

  .providers__search-input {
    color: #fff;
  }

  .providers__filter-btn {
    color: $gray-750;
    &:hover {
      color: $gray-500;
    }
    &.active {
      color: #fff;
      border-color: #fff;
    }
  }

  .providers__via-wallet-connect {
    @include btn-default-dark;
  }
}
</style>
